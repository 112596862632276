import React, { useEffect, useState, useRef } from "react";
import { Alert, Stack, Typography } from "@mui/material";
// import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { grey, red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandMore";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AdmAreaHeader } from "./AdmAreaHeader";
import { pld, postFetch } from "../driver";

export const RepAcnt = (props) => {
  const { TOKEN, delay, title } = props;
  const lastChange = useRef("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  // const [requery, setRequery] = useState(0);
  const [acnt, setAcnt] = useState("35");
  const showmoreRef = useRef(false);
  const acntbaseRef = useRef(acnt);
  const acntmoreRef = useRef("");
  // const pl = JSON.parse(window.atob(TOKEN.split(".")[1])); // payload

  const pl = pld(TOKEN); // payload

  // main menu
  const vmenu = (role) => {
    showmoreRef.current = false;
    if (role === "owner") {
      showmoreRef.current = true;
      return [
        { name: "Trade", acnt: "35", base: true },
        { name: "Каса", acnt: "3000", base: true },
        { name: "ДК", acnt: "3002", base: true },
        { name: "Борг", acnt: "36", base: true },
      ];
    } else if (role === "kant") {
      return [
        { name: "Trade", acnt: "35", base: true },
        { name: "Каса", acnt: "3000", base: true },
        { name: "Інкас", acnt: "3003", base: true },
        { name: "Борг", acnt: "36", base: true },
      ];
    } else {
      return [];
    }
  };

  // other menu
  const omenu = (role) => {
    if (role === "owner") {
      return [
        { name: "", acnt: "30" },
        // { name: "", acnt: "3000"},
        // { name: "", acnt: "3002", base: false },
        { name: "", acnt: "3003" },
        { name: "", acnt: "3500" },
        { name: "", acnt: "3501" },
        { name: "", acnt: "3600" },
        { name: "", acnt: "3602" },
        { name: "", acnt: "3605" },
        { name: "", acnt: "3607" },
        { name: "Капіт", acnt: "42" },
      ];
    } else {
      return [];
    }
  };

  // console.log(
  //   `#84hb RepAcnt RENDER ${JSON.stringify(pl)} menu= ${JSON.stringify(vmenu)}`
  // );
  const load = async () => {
    // console.log(
    //   `#34hn RepAcnt/load started` +
    //     JSON.stringify({ reqid: "acnt", code: acnt })
    // );
    lastChange.current = "";

    postFetch("/reports/index.php", TOKEN, { reqid: "acnt", code: acnt })
      .then((resp) => resp.json())
      .then((jresp) => {
        // console.log("#84hn");
        // console.log(jresp);
        let tdata = [];
        let r = 0;
        lastChange.current = jresp.rslt.reduce(
          (t, v) => (t = t < v.tm.substring(0, 10) ? v.tm.substring(0, 10) : t),
          ""
        );
        jresp.rslt.map((v) => {
          if (r === 0 || tdata[r - 1].code !== v.chid) {
            tdata.push({
              id: v.id,
              code: v.chid,
              total: 0,
              income: 0,
              outcome: 0,
              tm: "",
              expanded: false,
              chld: [],
            });
            ++r;
          }
          tdata[r - 1].chld.push(v);
          tdata[r - 1].total += Number(v.total);
          if (v.tm.substring(0, 10) === lastChange.current) {
            tdata[r - 1].income += Number(v.income);
            tdata[r - 1].outcome += Number(v.outcome);
          }
          if (tdata[r - 1].tm < v.tm) {
            tdata[r - 1].tm = v.tm;
          }
        });
        setData(tdata);
        setError(null);
        // console.log("#23df");
        // console.log(tdata);
      })
      .catch(function (err) {
        setError(err.message);
        // console.log(`#86dd dataFetch Request failed error=${error}`);
        // crntRepo_refresh = setTimeout(dataFetch, REFRESH * 2, vtabIndex);
      });
  };

  const handleToggleChange = (e, newValue) => {
    // console.log("#7ey3");
    // console.log(e);
    acntmoreRef.current = "";
    setAcnt(e.target.value);
    acntbaseRef.current = e.target.value;
  };
  const handleSelectChange = (e) => {
    acntbaseRef.current = "";
    setAcnt(e.target.value);
    acntmoreRef.current = e.target.value;
  };

  useEffect(() => {
    load();
    // let tmr = setTimeout(() => {
    //   setRequery((prevRequery) => (prevRequery > 10000 ? 0 : ++prevRequery));
    // }, 1000 * delay);
    // return () => {
    //   clearTimeout(tmr);
    // };
    const tmr = setInterval(load, 1000 * delay); //
    // const tmr = setInterval(load, 5000); // for testing
    return () => clearInterval(tmr);
  }, [acnt, delay]);

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    // const cred = props.shift == row.tm.substring(0, 10) ? red[900] : red[500];
    // const cgrey = props.shift == row.tm.substring(0, 10) ? grey[900] : grey[500];
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell padding="none">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TableCell>
          {/* <TableCell component="th" scope="row"> */}
          <TableCell padding="none">
            <Typography fontSize={"125%"} color={hue(1, row.tm, props.shift)}>
              {row.code}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              fontSize={"125%"}
              color={hue(row.total, row.tm, props.shift)}
            >
              {Math.abs(row.total).toLocaleString("uk-UA", {
                maximumFractionDigits: 0,
              })}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <div>
              <Typography fontSize={"90%"} color={hue(1, row.tm, props.shift)}>
                {Math.abs(row.income).toLocaleString("uk-UA", {
                  maximumFractionDigits: 0,
                })}
              </Typography>
              <Typography fontSize={"90%"} color={hue(1, row.tm, props.shift)}>
                {Math.abs(row.outcome).toLocaleString("uk-UA", {
                  maximumFractionDigits: 0,
                })}
              </Typography>
            </div>
          </TableCell>
          <TableCell align="right">
            <Typography fontSize={"90%"} color={hue(1, row.tm, props.shift)}>
              {hd(row.tm)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="none" colSpan={6}>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{ marginLeft: "10px" }}
            >
              {/* <Box sx={{ margin: 1 }}> */}
              <Table size="small" aria-label="shop's amount">
                <TableBody>
                  {row.chld.map((itm) => (
                    <TableRow
                      key={`${itm.chid}/${itm.shop}/${itm.acntno}`}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell />
                      <TableCell>
                        <Typography
                          fontSize={"90%"}
                          color={hue(1, itm.tm, props.shift)}
                        >
                          {itm.shop}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          fontSize={"90%"}
                          color={hue(itm.total, itm.tm, props.shift)}
                        >
                          {Math.abs(itm.total).toLocaleString("uk-UA", {
                            maximumFractionDigits: 0,
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {(pl.role === "owner" || pl.user === itm.shop) && (
                          <div>
                            <Typography
                              fontSize={"80%"}
                              color={hue(1, itm.tm, props.shift)}
                            >
                              {Math.abs(itm.income).toLocaleString("uk-UA", {
                                maximumFractionDigits: 0,
                              })}
                            </Typography>
                            <Typography
                              fontSize={"80%"}
                              color={hue(1, itm.tm, props.shift)}
                            >
                              {Math.abs(itm.outcome).toLocaleString("uk-UA", {
                                maximumFractionDigits: 0,
                              })}
                            </Typography>
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <div>
                          <Typography
                            fontSize={"80%"}
                            color={hue(1, itm.tm, props.shift)}
                          >
                            {hd(itm.tm)}
                          </Typography>
                          <Typography
                            fontSize={"80%"}
                            color={hue(1, itm.tm, props.shift)}
                          >
                            {itm.acntno}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* </Box> */}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <Stack gap={1} width="100%" sx={{ maxWidth: { md: 360 } }}>
      <AdmAreaHeader text={title} />
      <Stack
        direction={"row"}
        gap={1}
        width="100%"
        sx={{ justifyContent: "space-between" }}
      >
        <ToggleButtonGroup
          id="acntbase"
          value={acntbaseRef.current}
          onChange={handleToggleChange}
          aria-label="kantor selector"
          size="small"
          exclusive
          // sx={{ justifyContent: "center" }}
        >
          {vmenu(pl.role).map((v) => {
            return (
              <ToggleButton
                id={`acntList_${v.acnt}`}
                key={`acntList_${v.acnt}`}
                value={v.acnt}
                aria-label={v.acnt}
              >
                {v.name}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        {omenu(pl.role).length != 0 && (
          <FormControl size="small" sx={{ minWidth: 80 }}>
            <InputLabel id="acntmore-label">More</InputLabel>
            <Select
              labelId="acntmore-label"
              id="acntmore"
              value={acntmoreRef.current}
              label="More"
              onChange={handleSelectChange}
              autoWidth
            >
              {omenu(pl.role).map((v) => {
                return (
                  <MenuItem
                    id={`acntItem_${v.acnt}`}
                    key={`acntItem__${v.acnt}`}
                    value={v.acnt}
                    aria-label={v.acnt}
                  >
                    {v.name === "" ? v.acnt : v.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </Stack>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table" width="100%">
          {/*         <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell align="center">
                <Typography color={grey[500]}>сума</Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
      </TableHead> */}
          <TableBody>
            {data.map((row) => (
              <Row
                key={`${row.code}/${row.acntno}`}
                shift={lastChange.current}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {error && (
        <Alert severity="error">
          <Typography> {`${error}`}</Typography>
        </Alert>
      )}
    </Stack>
  );
};

const hue = (t, d, l) => {
  // console.log(`tm=${d.substring(0, 10)} shift=${l}`);
  if (d.substring(0, 10) === l) {
    return t < 0 ? red[900] : grey[900];
  } else {
    return t < 0 ? red[300] : grey[500];
  }
};

function hd(vdate, vshift = false) {
  if (vdate === undefined || vdate === "") {
    return "";
  }
  // if (vshift) {
  //   return vdate.substr(-5);
  // }
  let vnd = new Date();
  let vcd = new Date(vdate);
  //     return ''+Math.floor((vnd-vcd)/(24*60*60*1000))+'дн';
  if (1 < (vnd - vcd) / (365 * 24 * 60 * 60 * 1000)) {
    return "" + Math.floor((vnd - vcd) / (365 * 24 * 60 * 60 * 1000)) + "рік";
  } else if (1 < (vnd - vcd) / (30 * 24 * 60 * 60 * 1000)) {
    return "" + Math.floor((vnd - vcd) / (20 * 24 * 60 * 60 * 1000)) + "міс";
  } else if (1 < (vnd - vcd) / (7 * 24 * 60 * 60 * 1000)) {
    return "" + Math.floor((vnd - vcd) / (7 * 24 * 60 * 60 * 1000)) + "тиж";
  } else if (2 <= (vnd - vcd) / (24 * 60 * 60 * 1000)) {
    return "" + Math.floor((vnd - vcd) / (24 * 60 * 60 * 1000)) + "дн";
  } else if (1 < (vnd - vcd) / (24 * 60 * 60 * 1000)) {
    return "Вч " + vdate.substr(-5);
  } else {
    vdate = vdate.substr(11, 5);
  }

  return vdate;
}
