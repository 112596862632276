// export const PATH_TO_SERVER = "http://localhost/api/dev"; // for local testings
// export const PATH_TO_SERVER = "http://localhost/server"; // for local testings
// export const PATH_TO_SERVER = "/api/dev"; // for deployment

export const CD_KANTOR = [
  {
    chid: "ownermain",
    name: "ownermain",
    address: "",
    so: 5,
    worktm: "",
    phone: "",
    active: true,
  },
  {
    chid: "ownerwife",
    name: "ownerwife",
    address: "",
    so: 7,
    worktm: "",
    phone: "",
    active: true,
  },
  {
    chid: "BULK",
    name: "ГУРТ",
    address: "",
    so: 10,
    worktm: "",
    phone: "",
    active: true,
  },
  {
    chid: "FEYA",
    name: "м-н Фея",
    address: "",
    so: 20,
    worktm: "",
    phone: "",
    active: true,
  },
  {
    chid: "CITY",
    name: "Атлант СІТІ",
    address: "",
    so: 30,
    worktm: "",
    phone: "",
    active: true,
  },
  {
    chid: "ELSV",
    name: "Електросвіт",
    address: "",
    so: 35,
    worktm: "",
    phone: "",
    active: true,
  },
  {
    chid: "KHRV",
    name: "Талісман",
    address: "",
    so: 40,
    worktm: "",
    phone: "",
    active: false,
  },
  {
    chid: "DOBR",
    name: "Добромиль",
    address: "",
    so: 50,
    worktm: "",
    phone: "",
    active: false,
  },
];

export const CD_CURRENCY = [
  { id: "840", chid: "USD", name: "долар США", symb: "$", qty: 1, so: 10 },
  { id: "978", chid: "EUR", name: "ЄВРО", symb: "€", qty: 1, so: 15 },
  {
    id: "985",
    chid: "PLN",
    name: "польський злотий",
    symb: "zl",
    qty: 1,
    so: 20,
  },
  { id: "203", chid: "CZK", name: "чеська крона", symb: "Kč", qty: 1, so: 23 },
  {
    id: "826",
    chid: "GBP",
    name: "англійський фунт",
    symb: "£",
    qty: 1,
    so: 26,
  },
  {
    id: "124",
    chid: "CAD",
    name: "канадський долар",
    symb: "",
    qty: 1,
    so: 52,
  },
  {
    id: "756",
    chid: "CHF",
    name: "швейцарський франк",
    symb: "",
    qty: 1,
    so: 54,
  },
  {
    id: "036",
    chid: "AUD",
    name: "австралійський долар",
    symb: "",
    qty: 1,
    so: 56,
  },
  { id: "752", chid: "SEK", name: "шведська крона", symb: "", qty: 1, so: 58 },
  { id: "578", chid: "NOK", name: "норвезька крона", symb: "", qty: 1, so: 60 },
  { id: "208", chid: "DKK", name: "данська крона", symb: "", qty: 1, so: 62 },
  {
    id: "348",
    chid: "HUF",
    name: "угорський форинт",
    symb: "",
    qty: 100,
    so: 72,
  },
  { id: "975", chid: "BGN", name: "болгарський лев", symb: "", qty: 1, so: 74 },
  { id: "946", chid: "RON", name: "румунський лей", symb: "", qty: 1, so: 75 },
  { id: "949", chid: "TRY", name: "турецька ліра", symb: "", qty: 1, so: 76 },
  { id: "156", chid: "CNY", name: "китайський юань", symb: "", qty: 1, so: 78 },
  { id: "392", chid: "JPY", name: "японська єна", symb: "", qty: 10, so: 80 },
];
