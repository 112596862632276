// import * as React from "react";
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Paper from "@mui/material/Paper";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { VkAreaHeader } from "../components/VkAreaHeader";

import { pld, postFetch } from "../driver";

export const ChartProfit = (props) => {
  const { TOKEN, ...other } = props;
  const [profit, setProfit] = useState({ dataset: [], series: [] });
  const [wage, setWage] = useState({ dataset: [], series: [] });
  const [effic, setEffic] = useState({ dataset: [], series: [] });
  // const [data, setData] = useState({ xAxis: [], series: [] });
  // const [dcur, setDcur] = useState([]);
  const [ptotal, setPtotal] = useState({ dataset: [], series: [] });
  const [pusd, setPusd] = useState({ dataset: [], series: [] });
  const [peur, setPeur] = useState({ dataset: [], series: [] });
  const [ppln, setPpln] = useState({ dataset: [], series: [] });
  const [error, setError] = useState(null);
  // const [mode, setMode] = useState(0);

  // const pl = pld(TOKEN); // payload

  useEffect(() => {
    // console.log("#948j ChartProfit useEffect STARTED");
    // lastChange.current = "";
    let d = new Date();
    d.setMonth(d.getMonth() + 1);
    let pto = d.toISOString().substring(0, 7);
    d.setMonth(d.getMonth() - 5);
    let pfrom =
      d.toISOString().substring(0, 7) < "2024-02"
        ? "2024-02"
        : d.toISOString().substring(0, 7);
    // pfrom = "2024-02";
    // pto = "2024-06";

    // console.log(`#826g from=${pfrom} to=${pto}`);
    // setPeriod([
    //   d.setMonth(d.getMonth() - 6),
    //   new Date().setMonth(new Date().getMonth() + 1),
    // ]);
    // console.log(
    //   `#6g2 from=${new Date(period[0])
    //     .toISOString()
    //     .substring(0, 7)} to=${new Date(period[1])
    //     .toISOString()
    //     .substring(0, 7)}`
    // );
    postFetch("/reports/index.php", TOKEN, {
      reqid: "chartprofit",
      from: pfrom,
      to: pto,
    })
      .then((resp) => resp.json())
      .then((jresp) => {
        // console.log("#84hn ChartProfit jresp=" + JSON.stringify(jresp));
        const barRows = [];
        const barColsCshr = [];
        const barColsCur = ["USD", "EUR", "PLN", "other"];
        jresp.rslt.map((v, i, arr) => {
          if (!~barRows.indexOf(v.period)) {
            barRows.push(v.period);
          }
          if (!~barColsCshr.indexOf(v.cashier)) {
            barColsCshr.push(v.cashier);
          }
          v.amnt = v.total;
        });
        // console.log("#8u2 cols rows");
        // console.log(barRows);
        // console.log(barColsCshr);
        setProfit(cshrDataset(jresp.rslt, barRows, barColsCshr, ""));
        setWage(cshrDataset(jresp.rslt, barRows, barColsCshr, "", "_wage"));
        setEffic(cshrDataset(jresp.rslt, barRows, barColsCshr, "", "_effic"));
        setPtotal(curDataset(jresp.rslt, barRows, pfrom, pto));
        setPusd(cshrDataset(jresp.rslt, barRows, barColsCshr, "USD"));
        setPeur(cshrDataset(jresp.rslt, barRows, barColsCshr, "EUR"));
        setPpln(cshrDataset(jresp.rslt, barRows, barColsCshr, "PLN"));
      })
      .catch(function (err) {
        setError(err.message);
        console.log(`#63tv dataFetch Request failed error=${error}`);
      });
  }, []);
  return (
    <Stack gap={1} width="100%" sx={{ maxWidth: { md: 400 } }}>
      <VkAreaHeader text={props.title} />
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Працівники
        </AccordionSummary>
        <AccordionDetails>
          <Bar
            dataset={effic.dataset}
            xAxis={[{ scaleType: "band", dataKey: "month" }]}
            series={effic.series}
            title="Ефективність(дохід/ЗП)"
            width={360}
            height={360}
          />
          <Bar
            dataset={profit.dataset}
            xAxis={[{ scaleType: "band", dataKey: "month" }]}
            series={profit.series}
            title="Дохід"
            width={360}
            height={240}
          />
          <Bar
            dataset={wage.dataset}
            xAxis={[{ scaleType: "band", dataKey: "month" }]}
            series={wage.series}
            title="Зарплата"
            width={360}
            height={240}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Валюти(дохід)
        </AccordionSummary>
        <AccordionDetails>
          <Bar
            dataset={ptotal.dataset}
            xAxis={[{ scaleType: "band", dataKey: "month" }]}
            series={ptotal.series}
            title="Разом"
            width={360}
            height={240}
          />
          <Bar
            dataset={pusd.dataset}
            xAxis={[{ scaleType: "band", dataKey: "month" }]}
            series={pusd.series}
            title="USD"
            width={360}
            height={240}
          />
          <Bar
            dataset={peur.dataset}
            xAxis={[{ scaleType: "band", dataKey: "month" }]}
            series={peur.series}
            title="EUR"
            width={360}
            height={240}
          />
          <Bar
            dataset={ppln.dataset}
            xAxis={[{ scaleType: "band", dataKey: "month" }]}
            series={ppln.series}
            title="PLN"
            width={360}
            height={240}
          />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

function Bar(props) {
  const { title, ...others } = props;
  return (
    <>
      <>{title}</>
      <BarChart {...others} />
    </>
  );
}

const cshrDataset = (d, rows, cols, filt = "", suffix = "") => {
  const dset = [];
  const ser = [];
  let i = 0;
  cols.map((v) => {
    ser.push({ dataKey: v + suffix, label: v });
  });
  let tmprow = {};
  rows.map((v) => {
    tmprow = {};
    tmprow["pr"] = v;
    tmprow["month"] = new Date(v).toLocaleDateString("uk-UA", {
      month: "short",
    });
    for (i = 0; i < cols.length; ++i) {
      tmprow[cols[i]] = 0;
      tmprow[cols[i] + "_wage"] = 0;
      tmprow[cols[i] + "_effic"] = 0;
    }
    dset.push(tmprow);
  });
  d.map((v) => {
    if (filt === undefined || filt === "" || filt === v.chid) {
      for (i = 0; i < dset.length && dset[i].pr != v.period; ++i) {}
      if (i < dset.length) {
        dset[i][v.cashier] += Math.round(Number(v.amnt));
        dset[i][v.cashier + "_wage"] = Math.round(Number(v.wage));
      }
    }
  });
  dset.map((v) => {
    for (i = 0; i < cols.length; ++i) {
      if (v[cols[i]] !== undefined && v[cols[i]] !== 0) {
        v[cols[i] + "_effic"] = v[cols[i]] / v[cols[i] + "_wage"] - 1;
      } else {
        v[cols[i] + "_effic"] = 0;
      }
    }
  });
  return { dataset: dset, series: ser };
};

function curDataset(d, rows, start, stop) {
  const dset = [];
  const ser = [];
  const cols = ["USD", "EUR", "PLN", "other"];
  let i = 0;
  cols.map((v) => {
    ser.push({ dataKey: v, label: v === "other" ? "інші" : v });
  });
  // console.log(cols);
  let tmprow = {};
  rows.map((v) => {
    tmprow = {};
    tmprow["pr"] = v;
    tmprow["month"] = new Date(v).toLocaleDateString("uk-UA", {
      month: "short",
    });
    for (i = 0; i < cols.length; ++i) {
      tmprow[cols[i]] = 0;
    }
    dset.push(tmprow);
  });
  d.map((v) => {
    for (i = 0; i < dset.length && dset[i].pr != v.period; ++i) {}
    if (i < dset.length) {
      if (v.chid === "USD" || v.chid === "EUR" || v.chid === "PLN") {
        dset[i][v.chid] += Math.round(Number(v.total));
      } else {
        dset[i]["other"] += Math.round(Number(v.total));
      }
    }
  });
  return { dataset: dset, series: ser };
}

function Pie(props) {
  const { data, title, ...others } = props;
  return (
    <>
      <>{title}</>
      <PieChart
        series={[
          {
            data,
            paddingAngle: 3,
            cornerRadius: 10,
          },
        ]}
        {...others}
      />
    </>
  );
}

function pickUp(d, filt = "") {
  var rslt = [];
  let vid = 0;
  let vamnt = 0;
  let vwage = 0;
  let vlabel = "";
  d.map((v, i, arr) => {
    if (v.cashier !== "" && (filt === "" || filt === v.chid)) {
      if (v.cashier !== vlabel) {
        if (vlabel != "") {
          rslt.push({ id: vid, value: vamnt, label: vlabel, wage: vwage });
        }
        ++vid;
        vamnt = 0;
        vwage = 0;
        vlabel = v.cashier;
      }
      vamnt += Number(v.total);
      vwage = v.wage;
    }
    if (i === arr.length - 1) {
      rslt.push({ id: vid, value: vamnt, label: vlabel, wage: vwage });
    }
  });
  // console.log(rslt);
  return rslt;
}
