// import logo from "./logo.svg";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./Main.css";
import React, { useEffect, useState } from "react";
import { Alert, Container, Stack, Typography } from "@mui/material";
// import { Avatar, Box, Button, CssBaseline } from "@mui/material";

import { Sign } from "../Sign";
import { AdmFooter } from "./AdmFooter";
import { AdmHeader } from "./AdmHeader";
import { RepTabs } from "./RepTabs";
// import { VkOffer } from "../components/VkOffer";
import { authFetch, saveToken } from "../driver";
import { subscribe, unsubscribe } from "../events";

export const Main = (props) => {
  // const { CD_KANTOR, CD_CURRENCY } = props;
  // const [crntuser, setCrntuser] = useState({ name: "BULK", role: "owner" });
  const [token, setToken] = useState("");
  const [crntuser, setCrntuser] = useState({
    crntuser: "",
    term: "",
    role: "",
    user: "",
  });
  const [error, setError] = useState(null);
  // const [crntuser, setCrntuser] = useState({ name: "FEYA", role: "kant" });
  useEffect(() => {
    subscribe(
      "signin",
      // () => setRequery((prevRequery) => ++prevRequery)
      () => {
        // setCrntuser({ name: "", role: "" });
        setCrntuser({ crntuser: "", term: "", role: "", user: "" });
      }
    );
    return () => {
      unsubscribe("signin", () => {});
    };
  });

  useEffect(() => {
    setError(null);
    // console.log(`#836f useEffect started`);
    subscribe(
      "userChanged",
      // () => setRequery((prevRequery) => ++prevRequery)
      (resp) => {
        // console.log(
        //   `#836f key=12344321&usr=${resp.detail.usr}&psw=${resp.detail.psw}`
        // );
        // console.log(resp);

        authFetch(resp.detail)
          .then((res) => res.json())
          .then((jresp) => {
            // saveToken(jresp.token);
            // console.log(
            //   `#w345 fetch usr=${jresp.values.name} role=[${jresp.values.role}]`
            // );
            // const tt =
            // console.log(jresp.token);
            const [th, tp, ts] = jresp.token.split(".");
            const pl = JSON.parse(window.atob(tp));
            setToken(jresp.token);
            // console.log(token);
            setCrntuser(pl);
            setError(pl.role === "" ? "Autentication failed." : null);
          })
          .catch(function (err) {
            setError(err.message);
            // console.log(`#63tv dataFetch Request failed error=${error}`);
          });
      }
    );
    return () => {
      unsubscribe(
        "userChanged",
        () => {}
        // setRequery((prevRequery) => prevRequery)
      );
      // console.log(`#47hb vkrate useeffect req=${requery}`);
    };
  }, []);

  return (
    <Stack gap={1}>
      <AdmHeader />
      <Container maxWidth="xl">
        {(crntuser == null || crntuser.role === "") && <Sign />}
        {/* {crntuser.role !== "" && ( */}
        {token !== "" &&
          JSON.parse(window.atob(token.split(".")[1])) !== "" && (
            // <Alert severity="error">
            //   <Typography> works</Typography>
            // </Alert>
            <RepTabs TOKEN={token} />
          )}
        {error && (
          <Alert severity="error">
            <Typography> {error}</Typography>
          </Alert>
        )}
      </Container>
      <AdmFooter />
    </Stack>
  );
};

// export default App;
// const VkTest = () => {
//   return <h1>Blog Articles</h1>;
// };

// export default VkTest;
