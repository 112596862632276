import React, { useEffect, useState, useRef } from "react";
import { Alert, Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { grey } from "@mui/material/colors";
// import { Icon_Flag_BG, Icon_Flag_US } from "material-ui-country-flags";

import { getFetch } from "../driver";

/**
 *
 * @param {*} shop str
 * @param {*} delay int refresh interval in sec
 * @returns
 */
export const VkRate = (props) => {
  const { shop, delay, alwaysShow, ...other } = props;
  const show = useRef(true);
  const [mdata, setMdata] = useState([]);
  const [odata, setOdata] = useState([]);
  // const [requery, setRequery] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const color = useRef(["#f2f2f2", "#57ba98", grey[800]]); // Vivid

  const load = async () => {
    // console.log(`#8y3 AdmRate/load started`);
    getFetch("/rates/index.php", `shop=${shop}&reqid=ratebulk`)
      .then((resp) => resp.json())
      .then((jresp) => {
        let am = []; // main rate
        let ao = []; // other rate
        for (let k = 0; k < jresp.rslt.length; ++k) {
          // console.log(`#24td here`);
          if (
            // jresp.rslt.values[k].shop !== "BULK" &&
            Number(jresp.rslt[k].so) < 50
          ) {
            am.push(jresp.rslt[k]);
          } else {
            ao.push(jresp.rslt[k]);
          }
        }
        // console.log(`#23d show.current=${show.current}`);
        // console.log(jresp.rslt);
        setMdata(am);
        setOdata(ao);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setMdata(null);
        setOdata(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // console.log(`#34hn useEffect started`);
    // return;
    load();

    const tmr = setInterval(load, 1000 * delay); //
    // const tmr = setInterval(load, 5000); // for testing
    return () => clearInterval(tmr);
  }, [delay, shop]);

  const CurAmntCell = (props) => {
    const { amnt, color, bgcolor } = props;
    return (
      <TableCell align="center" padding={"none"} bgcolor={bgcolor}>
        <Typography color={color}>
          {Number(amnt) !== 0 ? Number(amnt).toPrecision(4) : ""}
        </Typography>
      </TableCell>
    );
  };

  const Row = (props) => {
    const { itm } = props;

    return (
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell align="center">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={`./flag/${itm.id}.svg`}
              sx={{
                width: "24px", //{ xs: "24px", sm: "4vmin" },
                height: "24px", // { xs: "24px", sm: "4vmin" },
                border: "solid lightgrey 1px",
              }}
            />
            <Typography>&nbsp;{itm.chid}&nbsp;</Typography>
            {/* <Typography variant="caption">
              {Number(itm.cqty) !== 1 ? itm.cqty : ""}
            </Typography> */}
          </Box>
          {/* <Typography variant="caption" fontSize={"75%"}>
        {itm.name}
      </Typography> */}
        </TableCell>
        <CurAmntCell
          amnt={itm.rbid}
          color={color.current[2]}
          bgcolor={color.current[0]}
        />
        <CurAmntCell
          amnt={itm.rask}
          color={color.current[2]}
          bgcolor={color.current[0]}
        />
        <CurAmntCell
          amnt={itm.bbid}
          color={color.current[2]}
          bgcolor={color.current[1]}
        />
        <CurAmntCell
          amnt={itm.bask}
          color={color.current[2]}
          bgcolor={color.current[1]}
        />
      </TableRow>
    );
  };

  const CurOther = (props) => {
    const { itm, ...other } = props;

    return (
      <Box {...other}>
        <Grid container spacing={{ xs: 0, sm: 1 }}>
          {odata.map((itm) => {
            return (
              <Grid
                id={itm.id}
                key={itm.id}
                item
                xs={4}
                sm={3}
                // md={2}
                align={"center"}
              >
                <Box sx={{ display: "flex" }}>
                  <Avatar
                    src={`./flag/${itm.id}.svg`}
                    sx={{
                      width: "1.2rem",
                      height: "1.2rem",
                      border: "solid lightgrey 1px",
                    }}
                  />
                  {/* <Typography variant="caption" gutterBottom>
                      &nbsp;{itm.cqty === "1" ? "" : itm.cqty}
                    </Typography> */}
                  <Typography
                    // variant="button"
                    fontSize="0.85rem"
                  >
                    &nbsp;{itm.chid}&nbsp; {Number(itm.rbid).toPrecision(4)}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  return (
    (alwaysShow || show.current) && (
      <Box {...other}>
        <Stack gap={2} width="100%">
          <Paper elevation={6} sx={{ padding: "0.3rem" }}>
            <TableContainer>
              {/* component={Paper} */}
              <Table size="small" aria-label="a dense table">
                {/* {props.shop === "BULK" && ( */}
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography color={color.current[2]}>НАЗВА</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      bgcolor={color.current[0]}
                    >
                      <Typography color={color.current[2]}>РОЗДРІБ</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      bgcolor={color.current[1]}
                      // sx={{ backgroundColor: green[200] }}
                    >
                      <Typography color={color.current[2]}>ГУРТ</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" padding={"none"}>
                      <Typography color={grey[500]} fontSize="0.9rem">
                        валюта
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding={"none"}
                      width={"18%"}
                      bgcolor={color.current[0]}
                    >
                      <Typography color={grey[500]} fontSize="0.9rem">
                        купівля
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding={"none"}
                      width={"18%"}
                      bgcolor={color.current[0]}
                    >
                      <Typography color={grey[500]} fontSize="0.9rem">
                        {/* fontSize={"90%"}*/}
                        продаж
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding={"none"}
                      width={"18%"}
                      bgcolor={color.current[1]}
                    >
                      <Typography color={grey[600]} fontSize="0.9rem">
                        купівля
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding={"none"}
                      width={"18%"}
                      bgcolor={color.current[1]}
                    >
                      <Typography color={grey[600]} fontSize="0.9rem">
                        {/* fontSize={"90%"}*/}
                        продаж
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* )} */}
                <TableBody>
                  {mdata.map((v) => {
                    return (
                      (Number(v.rbid) !== 0 ||
                        Number(v.rask) ||
                        Number(v.bbid) !== 0 ||
                        Number(v.bask)) !== 0 && (
                        <Row id={v.id} key={v.id} itm={v} />
                      )
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper elevation={6} sx={{ padding: "0.3rem" }}>
            <Stack gap={0.5}>
              <Box
                bgcolor={color.current[0]}
                // color={"info.contrastText"}
                padding={"5px 10px"}
              >
                <Typography fontSize="0.9rem">РОЗДРІБ, купівля</Typography>
              </Box>
              <CurOther />
            </Stack>
          </Paper>
          <Box></Box>
          {error && (
            <Alert severity="error">
              <Typography> {`${error}`}</Typography>
            </Alert>
          )}
          {props.footer && (
            <Alert
              icon={false}
              severity="warning"
              sx={{ justifyContent: "center" }}
            >
              <Typography variant="caption">{props.footer}</Typography>
            </Alert>
          )}
        </Stack>
      </Box>
    )
  );
};
