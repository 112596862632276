// import * as React from "react";
import React, { useRef } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
import { RepAcnt } from "./RepAcnt";
import { RepRate } from "./RepRate";
import { RepProfit } from "./RepProfit";
import { ChartProfit } from "./ChartProfit";
import { AdmRate } from "./AdmRate";
import { AdmOffer } from "./AdmOffer";
import { pld } from "../driver";

function RepTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {
        value === index && <>{children}</>
        // <Box /*sx={{ p: 1 }}*/>
        //   <Typography>{children}</Typography>
        // </Box>
      }
    </div>
  );
}

RepTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export const RepTabs = (props) => {
  const { TOKEN, ...other } = props;
  // const crntuser = useRef(JSON.parse(window.atob(TOKEN.split(".")[1])));
  const [value, setValue] = React.useState(0);
  const pl = pld(TOKEN); // payload

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div /*sx={{ width: "100%" }}*/>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Рахунки" {...a11yProps(0)} />
          <Tab label="Курс" {...a11yProps(1)} />
          {(pl.role === "owner" ||
            pl.user === "FEYA" ||
            pl.user === "CITY") && <Tab label="Звіти" {...a11yProps(2)} />}
          {(pl.role === "owner" ||
            pl.user === "FEYA" ||
            pl.user === "CITY") && <Tab label="Chart" {...a11yProps(3)} />}
        </Tabs>
      </Box>
      <Stack
        gap={1}
        direction={{ xs: "column", sm: "row" }}
        sx={{ marginTop: 1 }}
      >
        <RepTabPanel value={value} index={0}>
          <RepAcnt title="Стан рахунків" delay="210" TOKEN={TOKEN} />
        </RepTabPanel>
        <RepTabPanel value={value} index={1}>
          <RepRate title="Середній курс" delay="230" TOKEN={TOKEN} />
        </RepTabPanel>
        <RepTabPanel value={value} index={2}>
          <RepProfit title="Звіти" TOKEN={TOKEN} />
        </RepTabPanel>
        <RepTabPanel value={value} index={3}>
          <ChartProfit title="Аналіз" TOKEN={TOKEN} />
        </RepTabPanel>
        <AdmOffer title="Оголошення" delay="210" TOKEN={TOKEN} />
        <AdmRate
          // CD_KANTOR={CD_KANTOR}
          // CD_CURRENCY={CD_CURRENCY}
          TOKEN={TOKEN}
          title="Курси валют"
          kant={pl.term}
          delay="205"
          // delay="10"
        />
      </Stack>
    </div>
  );
};
