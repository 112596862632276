// import logo from "./logo.svg";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./BrdMain.css";
import React, { useEffect, useState } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
// import { Avatar, Box, Button, CssBaseline } from "@mui/material";

import { Sign } from "../Sign";
import { Advert } from "./Advert";
import { BrdSocial } from "./BrdSocial";
import { BrdHeader } from "./BrdHeader";
import { BrdRate } from "./BrdRate";
import { authFetch, getFetch } from "../driver";
import { subscribe, unsubscribe } from "../events";
import { BrdOffer } from "./BrdOffer";

export const BrdMain = (props) => {
  // const { CD_KANTOR, CD_CURRENCY } = props;
  // const [crntuser, setCrntuser] = useState({ name: "BULK", role: "owner" });
  const [token, setToken] = useState("");
  const [crntuser, setCrntuser] = useState({
    crntuser: "",
    term: "",
    role: "",
    user: "",
  });
  const [error, setError] = useState(null);
  const [bgno, setBgno] = useState(Math.floor((Math.random() * 10) % 5));
  const [bgtheme, setBgtheme] = useState("bg_winter");

  const load = async () => {
    getFetch("/board/index.php", "reqid=theme")
      .then((resp) => resp.json())
      .then((jresp) => {
        // console.log(`BrdMain #236y ${bgno} ${JSON.stringify(jresp.rslt[0])}`);
        // console.log(`BrdMain #236y url(/img/${jresp.theme})`);
        // bgimg = `url(/img/${jresp.rslt[0]}/bg${no}.png)`;
        setBgtheme(jresp.rslt[0].theme);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  useEffect(() => {
    subscribe(
      "signin",
      // () => setRequery((prevRequery) => ++prevRequery)
      () => {
        // setCrntuser({ name: "", role: "" });
        setCrntuser({ crntuser: "", term: "", role: "", user: "" });
      }
    );
    return () => {
      unsubscribe("signin", () => {});
    };
  });

  useEffect(() => {
    setError(null);
    // console.log(`#836f useEffect started`);
    subscribe(
      "userChanged",
      // () => setRequery((prevRequery) => ++prevRequery)
      (resp) => {
        // console.log(
        //   `#836f key=12344321&usr=${resp.detail.usr}&psw=${resp.detail.psw}`
        // );
        // console.log(resp);

        authFetch(resp.detail)
          .then((res) => res.json())
          .then((jresp) => {
            const [th, tp, ts] = jresp.token.split(".");
            const pl = JSON.parse(window.atob(tp));
            setToken(jresp.token);
            // console.log(token);
            setCrntuser(pl);
            setError(pl.role === "" ? "Autentication failed." : null);
          })
          .catch(function (err) {
            setError(err.message);
            // console.log(`#63tv dataFetch Request failed error=${error}`);
          });
      }
    );
    // load();
    // console.log(`BrdMain $9jq ` + bgtheme);
    // const tmr = setInterval(
    //   setBgno((prevBgno) => (prevBgno < 6 ? ++prevBgno : 0)),
    //   1000 * 60 * 15
    // ); //
    // console.log(`BrdMain #049j ${bgno} /img/bg_spring/bg${bgno}.png`);
    return () => {
      // clearTimeout(tmr);
      unsubscribe("userChanged", () => {});
    };
  }, [crntuser]);

  return (
    <div
      // component="img"
      style={{
        //   backgroundImage: `url(/img/${bgtheme}/bg${bgno}.jpg)`,
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        backgroundColor: "sandybrown", //"gold", //"#646cffaa",
        height: "100%",
        //   // width: "100%",
      }}
    >
      <Container>
        <Stack width={{ xs: "100%" }} gap={1} alignItems={"center"}>
          <BrdHeader />
          {crntuser.role === "" && <Sign />}
          {crntuser.role !== "" && (
            <>
              <BrdRate
                title="Курси валют"
                // footer="Курси мають виключно інформативний характер."
                shop={crntuser.term}
                alwaysShow={true}
                delay="150"
                // width="100%"
                // maxWidth={"480px"}
              />
              {crntuser.term != "FEYA" && (
                <BrdOffer shop={crntuser.term} delay="160" />
              )}
              <Advert />
            </>
          )}
        </Stack>
      </Container>
      <BrdSocial />
    </div>
  );
};
