import React, { useEffect, useState } from "react";
import { Alert, Box, buttonGroupClasses, Stack } from "@mui/material";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import CallIcon from "@mui/icons-material/Call";
import Paper from "@mui/material/Paper";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import DownloadIcon from "@mui/icons-material/Download";
// import UploadIcon from "@mui/icons-material/Upload";
// import CircleIcon from "@mui/icons-material/Circle";
import { grey } from "@mui/material/colors";
// import { Icon_Flag_BG, Icon_Flag_US } from "material-ui-country-flags";
// import { VkAreaHeader } from "./VkAreaHeader";

import { getFetch } from "../driver";

/**
 *
 * @param {*} delay int refresh interval in sec
 * @returns
 */
export const BrdOffer = (props) => {
  const { shop, delay, ...other } = props;
  const [bid, setBid] = useState([]);
  const [ask, setAsk] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fire, setFire] = useState(0);

  const load = async () => {
    // console.log(`#8y3 AdmRate/load started`);
    getFetch("/offers/index.php", `reqid=sel&shop=${shop}`)
      .then((resp) => resp.json())
      .then((jresp) => {
        let vbid = [],
          vask = [];
        jresp.rslt.map((v) => {
          if (v.bidask == "bid") {
            vbid.push(v);
          } else {
            vask.push(v);
          }
        });
        // return lst.sort((a, b) => { return a.sortorder - b.sortorder; });

        setBid(
          vbid.sort((a, b) => {
            return a.sortorder - b.sortorder;
          })
        );
        setAsk(
          vask.sort((a, b) => {
            return a.sortorder - b.sortorder;
          })
        );
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // console.log(`#24r4 AdmRate/useEffect [fire] started edit=${edit}`);
    // console.log(window.atob(TOKEN.split(".")[1]));
    load();
    return () => {};
  }, [fire]);

  useEffect(() => {
    // load();

    const tmr = setInterval(() => {
      setFire((prevFire) => (prevFire > 10000 ? 0 : ++prevFire));
    }, 1000 * delay); //
    // const tmr = setInterval(load, 5000); // for testing
    return () => clearInterval(tmr);
  });

  const Header = (props) => {
    const bg = "#57ba98"; //grey[200];
    return (
      // <Box bgcolor={"info.dark"} color={"info.contrastText"} padding={"10px"}>
      <Box bgcolor={bg} color={bg.contrastText} padding={"10px"}>
        <Typography fontSize={{ xs: "1rem", md: "2.5vmin" }}>
          {props.text}
        </Typography>
      </Box>
    );
  };

  const Offer = (props) => {
    const { v } = props;
    return (
      <Paper elevation={3} sx={{ padding: 1 }}>
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            component="img"
            sx={{
              height: { xs: "1rem", md: "3vmin" },
              // width: 350,
              maxHeight: { xs: 233, md: 167 },
              // maxWidth: { xs: 350, md: 250 },
              border: "solid lightgrey 1px",
              borderRadius: 1,
            }}
            alt="The flag."
            src={`./flag/${v.curid}.svg`}
          />
          <Box width="65%">
            <Stack width="100%" direction="row" justifyContent="space-between">
              <Typography>{v.name}</Typography>
              <Typography>{`від ${
                Math.abs(v.amnt) < 1500 ? "500" : "1 000"
              } до ${Math.abs(v.amnt).toLocaleString("uk-UA")}`}</Typography>
            </Stack>
            {v.onote !== undefined && v.onote !== "" && (
              <Box
                bgcolor={"whitesmoke"}
                color={"whitesmoke.contrastText"}
                p={"2px"}
              >
                <Typography>{v.onote}</Typography>
              </Box>
            )}
          </Box>
          <div width="20%" align-items="center">
            <Typography variant="button" fontSize={{ xs: "1rem", md: "3vmin" }}>
              {Number(v.price).toPrecision(4)}
            </Typography>
          </div>
        </Stack>
      </Paper>
    );
  };

  return (
    <Stack direction="row" width="100%" {...other}>
      <Box width="48%">
        {bid.length != 0 && (
          <Stack gap={1}>
            <Header text="Куплю" />
            <Stack gap={1}>
              {bid.map((v) => {
                return (
                  <Offer id={"pbid_" + v.oid} key={"pbkey_" + v.oid} v={v} />
                );
              })}
            </Stack>
          </Stack>
        )}
      </Box>
      <Box width="4%" />
      <Box width="48%">
        {ask.length != 0 && (
          <Stack gap={1}>
            <Header text="Продам" />
            <Stack gap={1}>
              {ask.map((v) => {
                return (
                  <Offer id={"paid_" + v.oid} key={"pakey_" + v.oid} v={v} />
                );
              })}
            </Stack>
          </Stack>
        )}
      </Box>
      {error && (
        <Alert severity="error">
          <Typography> {`${error}`}</Typography>
        </Alert>
      )}
    </Stack>
  );
};

//  humanDate
function hd(vdate) {
  if (vdate === undefined || vdate === "") {
    return "";
  }
  let vnd = new Date();
  let vcd = new Date(vdate);
  // !!! FOR TESTING
  // return vcd.toLocaleTimeString("en-GB").substring(0, 5);
  if (vnd.toISOString().substring(0, 10) === vdate.substring(0, 10)) {
    return vcd.toLocaleTimeString("en-GB").substring(0, 5);
  }
  return vcd.toISOString().substring(0, 10);
}

{
  /*<div id={"tid_" + v.oid} key={"tkey_" + v.oid}>
                  <Accordion id={"aid_" + v.oid} key={"akey_" + v.oid}>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id={"accordSum_" + v.oid}
    ba={v.bidask}
    cur={v.chid}
  >
    <Stack
      width={"100%"}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {v.bidask === "bid" ? (
        <CircleIcon fontSize="small" color="success" />
      ) : (
        <CircleIcon fontSize="small" color="info" />
      )}{" "}
      <Typography>
        {v.bidask === "bid" ? "куплю" : "продам"}
      </Typography>
      <Avatar
        alt={v.chid}
        src={`./flag/${v.curid}.svg`}
        sx={{
          width: 24,
          height: 24,
          border: "solid lightgrey 1px",
        }}
      />
      <Typography>{v.chid}</Typography>
      <Typography variant="button" fontSize={"125%"}>
        {Number(v.price).toPrecision(4)}
      </Typography>
      <Typography variant="caption">{hd(v.tm)}</Typography>
    </Stack>
  </AccordionSummary>
  <AccordionDetails>
    <Stack
      width={"100%"}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography>{v.name}</Typography>
      <Stack direction={"row"} gap={0.5}>
        <CallIcon fontSize="small" />
        {v.tel}
      </Stack>
    </Stack>
    <Typography>{`від ${
      Math.abs(v.amnt) < 1500 ? "500" : "1 000"
    } до ${Math.abs(v.amnt).toLocaleString(
      "uk-UA"
    )}`}</Typography>
    {v.onote !== undefined && v.onote !== "" && (
      <Box
        bgcolor={"whitesmoke"}
        color={"whitesmoke.contrastText"}
        p={"2px"}
      >
        <Typography>{v.onote}</Typography>
      </Box>
    )}
  </AccordionDetails>
</Accordion> 
</div>*/
}
