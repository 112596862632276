import React from "react";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const AdmAreaHeader = (props) => {
  return (
    <Box bgcolor={"info.dark"} color={"info.contrastText"} padding={"10px"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography>{props.text}</Typography>
        <Typography>{props.tm}</Typography>
      </Stack>
    </Box>
  );
};
