import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { grey } from "@mui/material/colors";
// import { Icon_Flag_BG, Icon_Flag_US } from "material-ui-country-flags";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { AdmAreaHeader } from "./AdmAreaHeader";

import { pld, postFetch } from "../driver";
import { CD_KANTOR, CD_CURRENCY } from "../constData";
import { publish, subscribe, unsubscribe } from "../events";

/**
 *
 * @param {*} title str
 * @param {*} shop str
 * @param {*} delay int refresh interval in sec
 * @returns
 */
export const AdmRate = (props) => {
  const { TOKEN, kant, delay, title } = props;
  const [data, setData] = useState([]);
  const [bulk, setBulk] = useState([]);

  const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [knt, setKnt] = useState(kant);
  const [edit, setEdit] = useState(false);
  const [fire, setFire] = useState(0);

  const pl = pld(TOKEN); // payload

  // const pl = JSON.parse(window.atob(TOKEN.split(".")[1])); // payload
  // console.log(`#23un AdmRate/ module RENDER knt=${knt}`);

  // last time change
  const lt = () => {
    let l = "";
    data.map((v) => {
      if (l < v.bidtm || l < v.asktm) {
        l = v.bidtm < v.asktm ? v.asktm : v.bidtm;
      }
    });
    // console.log(`#8ewj AdmRate lt=${l} data=${JSON.stringify(data)}`);
    return l;
  };

  const load = async () => {
    // async function load(msg) {
    // console.log(
    //   `#8y3 AdmRate/load started ${msg === undefined ? "???" : msg} knt=${knt}`
    // );
    postFetch("/rates/index.php", TOKEN, { reqid: "sel", shop: knt })
      .then((resp) => resp.json())
      .then((jresp) => {
        let td = [],
          tb = [];
        for (let r = 0; r < jresp.rslt.length; ++r) {
          if (jresp.rslt[r].prc == "bulk") {
            tb.push(jresp.rslt[r]);
          } else {
            td.push(jresp.rslt[r]);
          }
        }
        setData(td);
        setBulk(tb);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        // console.log(`#63tv dataFetch Request failed error=${error}`);
      });
  };

  const upd = async (e) => {
    // console.log(`#1111 AdmRate/upd started ` + JSON.stringify(e.detail));
    postFetch("/rates/index.php", TOKEN, { reqid: "upd", rates: e.detail })
      .finally(() => {
        setFire((prevFire) => (prevFire > 10000 ? 0 : ++prevFire));
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  useEffect(() => {
    // console.log(`#2222 AdmRate/useEffect load[fire] started edit=${edit}`);
    // console.log(window.atob(TOKEN.split(".")[1]));
    load();
    return () => {};
  }, [fire, knt]);

  useEffect(() => {
    // console.log(window.atob(TOKEN.split(".")[1]));
    // load("useEffect");
    subscribe("eSubmit_AdmRate", upd);
    subscribe("closeRateEditor_AdmRate", () => setEdit(false));
    const tmr = setInterval(() => {
      setFire((prevFire) => (prevFire > 10000 ? 0 : ++prevFire));
    }, 1000 * delay); //
    console
      .log
      // `#3333 AdmRate/useEffect setInterval started tmr=${tmr} fire=${fire}`
      ();
    // const tmr = setInterval(load, 5000); // for testing
    return () => {
      unsubscribe("eSubmit_AdmRate", () => {});
      unsubscribe("closeRateEditor_AdmRate", () => {});
      clearInterval(tmr);
    };
  }, [delay]);

  // useEffect(() => {
  //   console.log(`#4444 AdmRate/useEffec/eSubmitt_AdmRate started `);
  //   subscribe("closeRateEditor_AdmRate", () => setEdit(false));
  //   return () => {
  //     unsubscribe("closeRateEditor_AdmRate", () => {});
  //   };
  // });

  // useEffect(() => {
  //   console.log(`#4444 AdmRate/useEffec/eSubmitt_AdmRate started `);
  //   subscribe("eSubmit_AdmRate", upd);
  //   return () => {
  //     unsubscribe("eSubmit_AdmRate", () => {});
  //   };
  // });

  return (
    <Stack gap={1} width="100%" sx={{ maxWidth: { md: 360 } }}>
      <AdmAreaHeader text={title} tm={hd(lt())} />
      {!edit && (
        <Stack
          direction={"row"}
          gap={1}
          width="100%"
          sx={{ justifyContent: "space-between" }}
        >
          <ToggleButtonGroup
            value={knt}
            onChange={(e) => setKnt(e.target.value)}
            aria-label="kantor selector"
            exclusive
            size="small"
          >
            {CD_KANTOR.map((v) => {
              return (
                v.active &&
                v.chid.substring(0, 5) !== "owner" && (
                  <ToggleButton
                    id={`kntlist_${v.chid}`}
                    key={`kntlist_${v.chid}`}
                    value={v.chid}
                    aria-label={v.chid}
                  >
                    {v.chid}
                  </ToggleButton>
                )
              );
            })}
          </ToggleButtonGroup>
          <IconButton
            size="small"
            color="primary"
            disabled={!(pl.role === "owner" || knt === pl.user)}
            // variant="outlined"
            // value="check"
            // selected={edit}
            onClick={() => setEdit(!edit)}
          >
            <EditIcon />
          </IconButton>
        </Stack>
      )}
      {edit && (
        <EditRate
          CD_KANTOR={CD_KANTOR}
          CD_CURRENCY={CD_CURRENCY}
          rates={data}
          bulk={bulk}
          pl={pl}
          knt={knt}
        />
      )}
      {bulk.length != 0 && (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead sx={{ backgroundColor: "#57ba98" }}>
              <TableRow>
                <TableCell>
                  <Typography color={grey[600]}>ГУРТ</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography color={grey[600]}>купівля</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color={grey[600]}>
                    {/* fontSize={"90%"}*/}
                    продаж
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "#57ba98" }}>
              {bulk.map((v) => {
                return (
                  // (Number(v.f6) !== 0 || Number(v.f8) !== 0) && (
                  <TableRow
                    id={`id-${v.chid}-${v.prc}`}
                    key={`key-${v.chid}-${v.prc}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">
                      {" "}
                      {/*padding="none"*/}
                      <Stack direction={"row"}>
                        <Typography>{v.chid} &nbsp;</Typography>
                        <Typography variant="caption">
                          {Number(v.rqty) !== 1 ? v.rqty : ""}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {/*padding={"checkbox"}*/}
                      <Typography>
                        {Number(v.bid) !== 0
                          ? Number(v.bid).toPrecision(4)
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {/*padding={"checkbox"}*/}
                      <Typography variant="body1">
                        {Number(v.ask) !== 0
                          ? Number(v.ask).toPrecision(4)
                          : ""}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  // )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography color={grey[500]}>роздріб</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color={grey[500]}>купівля</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography color={grey[500]}>продаж</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((v) => {
              return (
                // (Number(v.f6) !== 0 || Number(v.f8) !== 0) && (
                <TableRow
                  id={`id-${v.chid}-${v.prc}`}
                  key={`key-${v.chid}-${v.prc}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center">
                    {" "}
                    {/*padding="none"*/}
                    <Stack direction={"row"}>
                      <Typography>{v.chid} &nbsp;</Typography>
                      <Typography variant="caption">
                        {Number(v.rqty) !== 1 ? v.rqty : ""}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    {/*padding={"checkbox"}*/}
                    <Typography>
                      {Number(v.bid) !== 0 ? Number(v.bid).toPrecision(4) : ""}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    {/*padding={"checkbox"}*/}
                    <Typography variant="body1">
                      {Number(v.ask) !== 0 ? Number(v.ask).toPrecision(4) : ""}
                    </Typography>
                  </TableCell>
                </TableRow>
                // )
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {error && (
        <Alert severity="error">
          <Typography> {`${error}`}</Typography>
        </Alert>
      )}
    </Stack>
  );
};

const EditRate = (props) => {
  const { CD_KANTOR, CD_CURRENCY, rates, bulk, pl, knt } = props;
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [save, setSave] = useState(false);

  const onSubmit = async (fd) => {
    // console.log(fd);
    let lst = [];
    if (Array.isArray(fd["fld-shop"])) {
      fd["fld-shop"].map((v, i) => {
        lst[i] = {
          shop: v,
          atclcode: fd["fld-cur"],
          pricecode: fd["fld-prc"],
          qty: fd["fld-qty"],
          bid: fd["fld-bid"],
          ask: fd["fld-ask"],
          tm: new Date().toISOString(),
        };
      });
    } else {
      lst = [
        {
          shop: fd["fld-shop"],
          atclcode: fd["fld-cur"],
          pricecode: fd["fld-prc"],
          qty: fd["fld-qty"],
          bid: fd["fld-bid"],
          ask: fd["fld-ask"],
          tm: new Date().toISOString(),
        },
      ];
    }
    publish("eSubmit_AdmRate", lst);
  };

  /**
   *
   * @param {*} v - atclcode
   * @param {*} a - rates array
   */
  const loadRate = (v, a) => {
    let i = 0;
    for (i = 0; i < a.length && a[i].atclcode != v; ++i) {}
    // console.log(`#94j i=${i} len=${data.length}`);
    if (i < a.length) {
      setValue("fld-qty", a[i].cqty);
      setValue("fld-bid", a[i].bid);
      setValue("fld-ask", a[i].ask);
    } else {
      setValue("fld-qty", "1");
      setValue("fld-bid", "");
      setValue("fld-ask", "");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={1}>
        <input {...register("fld-qty")} type="hidden" />

        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel id="fld-shop-label">Кантор</InputLabel>
          <Select
            {...register("fld-shop")}
            labelId="fld-shop-label"
            id="fld-shop"
            label="Кантор"
            defaultValue={pl.role === "kant" ? [pl.user] : [knt]}
            multiple
            onChange={(e) => {
              setSave(e.target.value.length && getValues("fld-cur") != "");
            }}
          >
            {CD_KANTOR.map((v) => {
              return (
                v.active &&
                v.chid.substring(0, 5) !== "owner" && (
                  <MenuItem
                    id={`kntlist_${v.chid}`}
                    key={`kntlist_${v.chid}`}
                    value={v.chid}
                    aria-label={v.chid}
                    disabled={!(pl.role == "owner" || pl.user == v.chid)}
                  >
                    {v.chid}
                  </MenuItem>
                )
              );
            })}
          </Select>
        </FormControl>
        <Stack direction={"row"} gap={1}>
          <FormControl size="small" sx={{ minWidth: 200 }}>
            <InputLabel id="fld-cur-label">Валюта</InputLabel>
            <Select
              {...register("fld-cur")}
              labelId="edcur-label"
              id="fld-cur"
              label="Валюта"
              defaultValue=""
              onChange={(e) => {
                setSave(getValues("fld-shop").length && e.target.value != "");
                loadRate(
                  e.target.value,
                  getValues("fld-prc") == "bulk" ? bulk : rates
                );
              }}
            >
              {CD_CURRENCY.map((v) => {
                return (
                  <MenuItem
                    id={`curItem_${v.chid}`}
                    key={`curItem_${v.chid}`}
                    value={v.id}
                    aria-label={v.chid}
                    dense={v.so > 20}
                  >
                    {`${v.chid} ${v.qty == 1 ? "" : v.qty} - ${v.name}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="fld-prc-label">Тип курсу</InputLabel>
            <Select
              {...register("fld-prc")}
              labelId="edpr-label"
              id="fld-prc"
              label="Роздріб"
              defaultValue=""
              onChange={(e) => {
                loadRate(
                  getValues("fld-cur"),
                  e.target.value == "bulk" ? bulk : rates
                );
              }}
            >
              <MenuItem
                id="edpr-def-id"
                key="edpr-def-key"
                value=""
                aria-label="Роздріб"
              >
                Роздріб
              </MenuItem>
              <MenuItem
                id="edprc-bulk-id"
                key="edprc-bulk-key"
                value="bulk"
                aria-label="ГУРТ"
              >
                ГУРТ
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <Stack direction={"row"} gap={1}>
          <TextField
            {...register("fld-bid")}
            label="Купівля"
            id="fld-bid"
            // type="number"
            size="small"
            InputLabelProps={{ shrink: true }}
            // defaultValue={rate.bid}
            // value={rate.bid}
            // onChange={(e) => {
            //   setBid(e.target.value);
            // }}
            //   sx={{ m: 1, width: "25ch" }}
            inputProps={{
              type: "number",
              step: 0.001,
              // step: Number(getValues("fld-bid")) < 10 ? 0.001 : 0.01,
              // inputMode: "decimal",
            }}
          />
          <TextField
            {...register("fld-ask")}
            label="Продаж"
            id="fld-ask"
            // type="number"
            size="small"
            InputLabelProps={{ shrink: true }}
            // defaultValue={rate.ask}
            // value={rate.ask}
            // onChange={(e) => {
            //   setAsk(e.target.value);
            // }}
            //   sx={{ m: 1, width: "25ch" }}
            inputProps={{
              type: "number",
              step: 0.001,
              // step: Number(getValues("fld-ask")) < 10 ? 0.001 : 0.01,
              // inputMode: "decimal",
            }}
          />
        </Stack>
        <Stack direction={"row"} gap={1} justifyContent={"flex-end"}>
          <Button
            // type="reject"
            variant="outlined"
            startIcon={<CloseIcon />}
            // color={grey[200]}
            onClick={() => publish("closeRateEditor_AdmRate")}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="success"
            disabled={!save}
            startIcon={<CheckIcon />}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

//  humanDate
function hd(vdate) {
  if (vdate === undefined || vdate === "") {
    return "";
  }
  let vnd = new Date();
  let vcd = new Date(vdate);
  // !!! FOR TESTING
  // return vcd.toLocaleTimeString("en-GB").substring(0, 5);
  if (vnd.toISOString().substring(0, 10) === vdate.substring(0, 10)) {
    return vcd.toLocaleTimeString("en-GB").substring(0, 5);
  }
  return vcd.toISOString().substring(0, 10);
}
