import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Alert, Avatar, Box, Button, Stack, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CallIcon from "@mui/icons-material/Call";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { grey } from "@mui/material/colors";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import DownloadIcon from "@mui/icons-material/Download";
// import UploadIcon from "@mui/icons-material/Upload";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
// import { grey } from "@mui/material/colors";
// import { Icon_Flag_BG, Icon_Flag_US } from "material-ui-country-flags";
import { AdmAreaHeader } from "./AdmAreaHeader";

import { pld, postFetch, getFetch } from "../driver";
import { CD_KANTOR, CD_CURRENCY } from "../constData";
// import { publish, subscribe, unsubscribe } from "../events";

/**
 *
 * @param {*} title str
 * @param {*} delay int refresh interval in sec
 * @returns
 */
export const AdmOffer = (props) => {
  const { TOKEN, delay, title } = props;
  const [data, setData] = useState([]);
  const [edited, setEdited] = useState("none"); // edited offer

  const [loading, setLoading] = useState(true);
  const { register } = useForm();

  const [error, setError] = useState(null);
  const [fltba, setFltba] = useState("");
  const [fltcur, setFltcur] = useState("");
  const [myonly, setMyonly] = useState(false);

  const pl = pld(TOKEN); // payload

  // const pl = () => JSON.parse(window.atob(TOKEN.split(".")[1])); // payload

  const ofcurs = () => {
    let lst = [];
    let k = 0;
    data.forEach((v) => {
      for (k = 0; k < lst.length && lst[k].chid !== v.chid; ++k) {}
      if (k === lst.length) {
        lst.push({
          chid: v.chid,
          name: v.name,
          so: Number(v.sortorder),
        });
      }
    });
    return lst.sort((a, b) => {
      return a.so - b.so;
    });
  };

  const load = async () => {
    // console.log(`#94u AdmOffer load started...`);
    // fetch(`${PATH_TO_SERVER}/offers/index.php?reqid=sel`, {
    //   method: "get",
    //   mode: "cors",
    //   headers: {
    //     "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    //   },
    // })
    getFetch("/offers/index.php", "reqid=sel")
      .then((resp) => resp.json())
      .then((jresp) => {
        // console.log("#91df ");
        // console.log(jresp.rslt);
        if (jresp.rslt === undefined) {
          setData([]);
        } else {
          setData(jresp.rslt);
        }
        setError(null);
      })
      .catch((err) => {
        setError(`This is an HTTP error: Err = ${err.message}`);
        // setData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = async (data) => {
    // console.log(data);
    const b = {
      reqid: "upd",
      ofid: data.edoid,
      shop: data.edknt,
      cur: data.edcur,
      ba: data.edba,
      amnt: data.edamnt,
      price: data.edrate,
      tel: data.edtel,
      note: data.ednote,
      tm: new Date().toISOString(),
    };
    // console.log(`#8ej AdmOffer/onSubmit body=` + JSON.stringify(b));
    // console.log("#18j EditOffer started body=" + JSON.stringify(b));
    // fetch(`${PATH_TO_SERVER}/offers/index.php?api_token=${TOKEN}`, {
    //   method: "post",
    //   // mode: "cors",
    //   headers: {
    //     "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    //   },
    //   body: "data=" + JSON.stringify(b),
    // })
    postFetch("/offers/index.php", TOKEN, b)
      .then(() => {
        setEdited("none");
        load();
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  const EditOffer = (props) => {
    const { offer } = props;
    // console.log(`#01uh EditOffer started pl=${JSON.stringify(pl)}`);
    // console.log(`#01uh EditOffer started offer=${JSON.stringify(offer)}`);
    const { register, handleSubmit } = useForm();

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("edoid")}
          id="edoid"
          margin="normal"
          size="small"
          label="oid"
          // type="number"
          defaultValue={offer.oid ?? ""}
          sx={{ display: "none" }}
        ></TextField>
        <Stack
          direction={"row"}
          gap={1}
          justifyContent={"space-between"}
          sx={{ padding: "8px 0px" }}
        >
          <FormControl size="small" sx={{ minWidth: 100 }}>
            <InputLabel id="edknt-label">Кантор</InputLabel>
            <Select
              {...register("edknt")}
              id="edknt"
              labelId="edknt-label"
              label="Кантор"
              defaultValue={offer.shop}
              readOnly={pl.role !== "owner"}
            >
              {CD_KANTOR.map((v) => {
                return (
                  v.active && (
                    <MenuItem
                      id={`knt_${v.chid}`}
                      key={`knt_${v.chid}`}
                      value={v.chid}
                      aria-label={v.chid}
                    >
                      {v.chid}
                    </MenuItem>
                  )
                );
              })}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 200 }}>
            <InputLabel id="edtel-label">Телефон</InputLabel>
            <Select
              {...register("edtel")}
              id="edtel"
              labelId="edtel-label"
              // name="edtel"
              label="Телефон"
              defaultValue={offer.tel ?? "(096)001-3600"}
            >
              {[
                "(096)001-3600", // main
                "(067)499-5988", // Vas
                "(097)516-9138", // Dec
                "(098)127-4894", // Pro
                "(068)864-8748", // Kuz
              ].map((v) => {
                return (
                  <MenuItem
                    id={`knt_${v}`}
                    key={`knt_${v}`}
                    value={v}
                    aria-label={v}
                  >
                    {v}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction={"row"}
          gap={1}
          justifyContent={"space-between"}
          sx={{ padding: "8px 0px" }}
        >
          <FormControl size="small" sx={{ minWidth: 100 }}>
            <InputLabel id="edcur-label">Валюта</InputLabel>
            <Select
              {...register("edba")}
              id="edba"
              labelId="edcur-label"
              label="Куп/Прод"
              defaultValue={offer.bidask ?? "bid"}
            >
              {[
                { code: "bid", name: "Купляю" },
                { code: "ask", name: "Продаю" },
              ].map((v) => {
                return (
                  <MenuItem
                    id={`ba_${v.code}`}
                    key={`ba_${v.code}`}
                    value={v.code}
                    aria-label={v.name}
                  >
                    {v.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 200 }}>
            <InputLabel id="edcur-label">Валюта</InputLabel>
            <Select
              {...register("edcur")}
              labelId="edcur-label"
              id="edcur"
              label="Валюта"
              defaultValue={offer.chid ?? "USD"}
            >
              {CD_CURRENCY.map((v) => {
                return (
                  <MenuItem
                    id={`curItem_${v.chid}`}
                    key={`curItem_${v.chid}`}
                    value={v.chid}
                    aria-label={v.chid}
                    dense={v.so > 20}
                  >
                    {`${v.chid} ${v.qty == 1 ? "" : v.qty} - ${v.name}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction={"row"} gap={1}>
          <TextField
            {...register("edamnt")}
            id="edamnt"
            margin="normal"
            size="small"
            // required
            // fullWidth
            label="Sum"
            type="number"
            defaultValue={offer.amnt ?? ""}
          />
          <TextField
            {...register("edrate")}
            id="edrate"
            margin="normal"
            size="small"
            // required
            // fullWidth
            // name="edrate"
            label="Rate"
            defaultValue={offer.price ?? ""}
            inputProps={{
              type: "number",
              step: offer.price < 10 ? 0.001 : 0.01,
              // inputMode: "decimal",
            }}
          />
        </Stack>
        <TextField
          {...register("ednote")}
          id="ednote"
          margin="normal"
          size="small"
          fullWidth
          label="Note"
          defaultValue={offer.onote ?? ""}
          // autoComplete="current-password"
        />
        <Stack direction={"row"} gap={1} justifyContent={"flex-end"}>
          <Button
            // type="reject"
            variant="outlined"
            startIcon={<CloseIcon />}
            // color={grey[200]}
            onClick={() => {
              setEdited("none");
            }}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="success"
            startIcon={<CheckIcon />}
          >
            Save
          </Button>
        </Stack>
      </form>
    );
  };

  useEffect(() => {
    // console.log(`#34hn AdmOffer/useEffect started`);
    // console.log(`#7eu3 AdmOffer useEffect started delay=${delay}`);
    load();
    const tmr = setInterval(() => {
      // console.log(`#48u AdmOffer tmr started t=${tmr} edited=${edited}`);
      if (edited == "none") {
        load();
      }
    }, 1000 * delay); //
    return () => {
      clearInterval(tmr);
    };
  }, [delay, edited]);

  return (
    <Stack
      gap={1}
      width="100%"
      sx={{ maxWidth: { md: 360 }, minWidth: { sm: 300 } }}
    >
      <AdmAreaHeader text={title} />
      {/* <form> */}
      <Stack
        direction={"row"}
        justifyContent={"center"}
        useFlexGap
        flexWrap="wrap"
      >
        <FormControl sx={{ m: 1, minWidth: 130 }} size="small">
          <InputLabel id="labelBA">Операції</InputLabel>
          <Select
            {...register("root.ba")}
            labelId="labelBA"
            id="ba"
            // defaultValue=""
            value={fltba}
            label="Операції"
            onChange={(e) => setFltba(e.target.value)}
          >
            <MenuItem value="" divider={true}>
              <b>Всі</b>
            </MenuItem>
            <MenuItem value="bid">
              <Typography variant="inherit">Куплю</Typography>
            </MenuItem>
            <MenuItem value="ask">
              <Typography variant="inherit">Продам</Typography>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="labelCur">Валюти</InputLabel>
          <Select
            labelId="labelCur"
            id="cur"
            value={fltcur}
            label="Всі валюти"
            onChange={(e) => setFltcur(e.target.value)}
          >
            <MenuItem key="" value="" divider={true}>
              <b>Всі валюти</b>
            </MenuItem>
            {ofcurs().map((v) => {
              return (
                <MenuItem key={v.chid} value={v.chid}>
                  {v.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {(pl.role === "owner" || pl.role === "kant") && (
          <>
            <FormControlLabel
              {...register("root.my")}
              control={<Switch />}
              size="small"
              label="Мої"
              defaultValue={myonly}
              onChange={(e) => setMyonly(e.target.checked)}
            />
            <Button
              variant="outlined"
              size="small"
              startIcon={<CheckIcon />}
              onClick={() => {
                // stopTTT();
                setEdited("none");
                let o = data;
                o.unshift({
                  oid: "",
                  curid: "840",
                  chid: "USD",
                  name: "Долар США",
                  qty: "",
                  shop: pl.user,
                  bidask: "bid",
                  amnt: "",
                  price: "",
                  tel: "",
                  onote: "",
                  tm: "",
                  sortorder: "10",
                });
                setData(o);
                setEdited("");
              }}
            >
              Нова заявка
            </Button>
          </>
        )}
      </Stack>
      {/* </form> */}
      <Stack flexWrap="wrap" gap={1}>
        {data.map((v, i) => {
          return (
            (fltba === "" || fltba === v.bidask) &&
            (fltcur === "" || fltcur === v.chid) &&
            (!myonly || v.shop === pl.user) && (
              <Paper
                id={"ppid_" + v.oid}
                key={"ppkey_" + v.oid}
                elevation={3}
                sx={{ padding: 1 }}
              >
                <>
                  {edited !== v.oid && (
                    <>
                      <Stack
                        // width={"100%"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        {v.bidask === "bid" ? (
                          <CircleIcon fontSize="small" color="success" />
                        ) : (
                          <CircleIcon fontSize="small" color="info" />
                        )}
                        <Typography>
                          {v.bidask === "bid" ? "куплю" : "продам"}
                        </Typography>
                        <Avatar
                          alt={v.chid}
                          src={`./flag/${v.curid}.svg`}
                          sx={{
                            width: 24,
                            height: 24,
                            border: "solid lightgrey 1px",
                          }}
                        />
                        <Typography>{v.chid}</Typography>
                        <Typography variant="button" fontSize={"125%"}>
                          {Number(v.price).toPrecision(4)}
                        </Typography>
                        <Typography variant="caption">{hd(v.tm)}</Typography>
                      </Stack>
                      <Stack
                        width={"100%"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography>{v.name}</Typography>
                        <Stack direction={"row"} gap={0.5}>
                          <CallIcon fontSize="small" />
                          {v.tel}
                        </Stack>
                      </Stack>
                      <Stack
                        direction={"row"}
                        gap={0.5}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography>{`від ${
                          Math.abs(v.amnt) < 1500 ? "500" : "1 000"
                        } до ${Math.abs(v.amnt).toLocaleString(
                          "uk-UA"
                        )}`}</Typography>
                        {(pl.role === "owner" || pl.role === "kant") && (
                          <Button
                            variant="outlined"
                            disabled={pl.role !== "owner" && pl.user !== v.shop}
                            startIcon={<EditIcon />}
                            onClick={() => {
                              // stopTTT();
                              setEdited(v.oid);
                            }}
                          >
                            {v.shop}
                          </Button>
                        )}
                      </Stack>
                      {v.onote !== undefined && v.onote !== "" && (
                        <Box
                          bgcolor={"whitesmoke"}
                          color={"whitesmoke.contrastText"}
                          p={"2px"}
                        >
                          <Typography>{v.onote}</Typography>
                        </Box>
                      )}
                    </>
                  )}
                  {edited === v.oid && <EditOffer offer={v} />}
                </>
              </Paper>
            )
          );
        })}
      </Stack>
      {error && (
        <Alert severity="error">
          <Typography> {`${error}`}</Typography>
        </Alert>
      )}
    </Stack>
    // )
  );
};

//  humanDate
function hd(vdate) {
  if (vdate === undefined || vdate === "") {
    return "";
  }
  let vnd = new Date();
  let vcd = new Date(vdate);
  // !!! FOR TESTING
  // return vcd.toLocaleTimeString("en-GB").substring(0, 5);
  if (vnd.toISOString().substring(0, 10) === vdate.substring(0, 10)) {
    return vcd.toLocaleTimeString("en-GB").substring(0, 5);
  }
  return vcd.toISOString().substring(0, 10);
}
