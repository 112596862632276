// const PATH_TO_SERVER = "http://localhost/api/v1"; // for local testings
const PATH_TO_SERVER = "/api/v1"; // for deployment

// let token = "";
// let payload = { crntuser: "", role: "", user: "" };

// const saveToken = (t) => {
//   token = t;
//   payload = JSON.parse(window.atob(t.split(".")[1])); // payload
//   // console.log(`#e8h driver/saveToken tt=${token}`);
// };

// const pl = () => {
//   return payload;
// };

/**
 * payload
 * @param {*} t token
 * @returns payload
 */
const pld = (t) => {
  if (t == undefined || t == "") {
    return { crntuser: "", term: "", role: "", user: "" }; // payload
  } else {
    return JSON.parse(window.atob(t.split(".")[1])); // payload
  }
};

const postFetch = async (path, token, jdata) => {
  const resp = fetch(`${PATH_TO_SERVER}${path}?api_token=${token}`, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: "data=" + JSON.stringify(jdata),
    // body: `key=${MN_SID}&usr=${CRNTUSER.name}&query=${vquery}`,
  });
  return resp;
};

const getFetch = async (path, jdata) => {
  if (jdata !== undefined && jdata !== "") {
    jdata = "?" + jdata;
  }
  const resp = fetch(`${PATH_TO_SERVER}${path}${jdata}`, {
    method: "get",
    mode: "cors",
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });
  return resp;
};

function authFetch(usr) {
  const resp = fetch(`${PATH_TO_SERVER}/auth/index.php`, {
    method: "post",
    mode: "cors",
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: `data=${usr}`,
  });
  return resp;
}

function parse(raw) {
  try {
    return JSON.parse(raw);
  } catch (err) {
    return false;
  }
}

export { pld, postFetch, getFetch, authFetch, parse };
