import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
import { Container, Stack } from "@mui/material";
import { VkFooter } from "./components/VkFooter";
import { VkHeader } from "./components/VkHeader";
import { VkRate } from "./components/VkRate";
// import { VkRate } from "./components/~VkRate_08";
// import { VkRate } from "./components/~VkRate_09";
import { VkOffer } from "./components/VkOffer";

function App(props) {
  // document.title = "КанторФК";
  return (
    <Stack gap={1}>
      <VkHeader />
      <Container maxWidth="xl">
        <Stack
          direction={"row"}
          gap={2}
          flexWrap="wrap"
          justifyContent={"center"}
        >
          <VkRate
            title="Курси валют"
            footer="Курси мають виключно інформативний характер."
            shop="CITY"
            alwaysShow={true}
            delay="200"
            // width="100%"
            maxWidth={"480px"}
          />
          <VkOffer
            title="Оголошення"
            delay="210"
            // width="100%"
            maxWidth={"480px"}
          />
        </Stack>
      </Container>
      <VkFooter />
    </Stack>
  );
}

export default App;
