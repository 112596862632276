import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import { Main } from "./route_knt/Main";
import { BrdMain } from "./route_board/BrdMain";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        {/*<Route index element={<App />} />
         <Route path="blogs" element={<Blogs />} />
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<NoPage />} /> */}
      </Route>
      <Route path="/knt" element={<Main />}></Route>
      <Route path="/board" element={<BrdMain />}></Route>
    </Routes>
  </BrowserRouter>
);

{
  /* <React.StrictMode>
    <App />
  </React.StrictMode> */
}
